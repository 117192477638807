/* You can add global styles to this file, and also import other style files */
//$font-size: 12px;

// Themes laden
//@import "~@progress/kendo-theme-default/scss/all";
//@import "~@otris/kendo-ui/themes/default/all";
//@import "~@otris/privacy-ng-lib/themes/reporting/all";

html, body {
	height: 100%;
	width: 100%;
	margin: 0;
	padding: 0;
}

body {
	font-size: 0.8em;
	font-family: Roboto, Arial, Helvetica, Arial, sans-serif;
}

* {
	box-sizing: border-box;
}

.otris-form {
	background-color: transparent;
}

/*.otris-pd-groupbox {
	background-color: whitesmoke;
	margin: 1em;
	padding: 1em;
	border-radius: 0.5em;
}*/

.otris-overlay.k-popup {
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: rgba(128,128,128,0.5);
	width: 100%;
	height: 100%;
	border-width: 0;
}

//.otris-overlay {
//	display: grid;

	/* Für neue Projekte das benutzen (hier im standard noch ungetestet)
  display: flex;
  justify-content: center;
	align-items: center;
	*/

/*	background-color: rgba(0,0,0,0.5);
	width: 100%;
	height: 100%;
	border-width: 0;
} */

kendo-toolbar.k-toolbar {
	background-color: transparent;
	button.k-button {
		background-color: inherit;
	}
}

// für TestPersistenceForm
.otris-toolbar-button-1 {
	color: blue;
}

